import { apiClients } from '../../index';
import { ApiResponse } from '../../../types';
import { EEventUserRatingType } from '../../../enums';
import { Paging } from '../../../../types';
import { IUserItem } from '../../../models/user-item';
import { IEventRating } from '../../../models/event-rating';

interface GetEventUserRatingsRequest extends Paging {
  userRatingType?: EEventUserRatingType;
}

export interface IEventRatingResponse extends IEventRating, ApiResponse {}

export interface UserRatingItem {
  user?: IUserItem;
  place?: number;
  rating?: number;
  isCurrentUser?: boolean;
}

/**
 * Рейтинг ивента
 */
export const rating = {
  get: (id: number, request?: GetEventUserRatingsRequest) => {
    return apiClients.default.get<IEventRatingResponse>(`v1/event/${id}/rating`, {
      params: request,
    });
  },
};
