import appConfig from '../../app-config';
import { createHttpClient } from '../http-client';
import { createApiClient } from '../api-client';

export const client = {
  default: createHttpClient(appConfig.apiUrl),
  apiService: createHttpClient(appConfig.apiServiceUrl), // only geolocation
};

export const apiClients = {
  default: createApiClient(createHttpClient(appConfig.apiUrl)),
  apiService: createApiClient(createHttpClient(appConfig.apiServiceUrl)), // only geolocation
};
