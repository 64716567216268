import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AppProviders } from './providers/AppProviders';

const MobileLayout = React.lazy(async () => import('./views/layout/mobile-layout'));
const EmptyPage404 = React.lazy(async () => import('@page/empty-page-404/empty-page-404'));

const App = () => {
  return (
    <BrowserRouter>
      <AppProviders>
        <Routes>
          <Route path="/*" element={<MobileLayout />} />
          <Route path="/m/*" element={<MobileLayout />} />
        </Routes>
      </AppProviders>
    </BrowserRouter>
  );
};

export default App;
