import React from 'react';
import { SchemeProvider } from './scheme-provider';
import { ThemeProvider } from './theme-provider';
import { LocalizationProvider } from './localization-provider';
import { BottomSheetProvider } from './bottom-sheet-provider';
import { OptionsLoaderProvider } from './options-loader-provider';

export const AppProviders = ({ children }: { children: React.ReactNode }) => (
  <SchemeProvider>
    <ThemeProvider>
      <LocalizationProvider>
        <OptionsLoaderProvider>
          <BottomSheetProvider>{children}</BottomSheetProvider>
        </OptionsLoaderProvider>
      </LocalizationProvider>
    </ThemeProvider>
  </SchemeProvider>
);
